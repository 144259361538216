import { render, staticRenderFns } from "./MealMenu.vue?vue&type=template&id=4987a4d0&scoped=true&"
import script from "./MealMenu.vue?vue&type=script&lang=js&"
export * from "./MealMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4987a4d0",
  null
  
)

export default component.exports