var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"my-4"},[_c('b-row',[_c('b-col',{staticClass:"offset-0 offset-md-3 col-12",attrs:{"md":"6"}},[_c('h1',[_vm._v("Register")]),_c('hr'),_vm._v(" "),_c('br'),_c('form',{attrs:{"name":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('br'),_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control",class:{
                                    'is-invalid': _vm.shouldAppendErrorClass(_vm.$v.form.email),
                                    'is-valid': _vm.shouldAppendValidClass(_vm.$v.form.email)
                                },attrs:{"type":"email","name":"email","id":"email","placeholder":"your email id goes here"},domProps:{"value":(_vm.form.email)},on:{"blur":function($event){return _vm.$v.form.email.$touch()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "email", $event.target.value)}}}),(_vm.$v.form.email.$error)?_c('div',[(!_vm.$v.form.email.required)?_c('div',{staticClass:"error-message"},[_c('small',[_vm._v("The email field is required")])]):_vm._e(),(!_vm.$v.form.email.email)?_c('div',{staticClass:"error-message"},[_c('small',[_vm._v("Invalid email address")])]):_vm._e()]):_vm._e()]),_c('br'),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.shouldAppendErrorClass(_vm.$v.form.password),
                                        'is-valid': _vm.shouldAppendValidClass(_vm.$v.form.password)
                                    },attrs:{"type":"password","autocomplete":"off","name":"password","id":"password"},domProps:{"value":(_vm.form.password)},on:{"blur":function($event){return _vm.$v.form.password.$touch()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "password", $event.target.value)}}}),(_vm.$v.form.password.$error)?_c('div',[(!_vm.$v.form.password.required)?_c('div',{staticClass:"error-message"},[_c('small',[_vm._v("The password field is required")])]):_vm._e(),(!_vm.$v.form.password.minLength)?_c('div',{staticClass:"error-message"},[_c('small',[_vm._v("The password must have at least 8 characters")])]):_vm._e(),(!_vm.$v.form.password.containsUppercase)?_c('div',{staticClass:"error-message"},[_c('small',[_vm._v("The password must have at least 1 uppercase character")])]):_vm._e(),(!_vm.$v.form.password.containsLowercase)?_c('div',{staticClass:"error-message"},[_c('small',[_vm._v("The password must have at least 1 lowercase character")])]):_vm._e(),(!_vm.$v.form.password.containsNumber)?_c('div',{staticClass:"error-message"},[_c('small',[_vm._v("The password must have at least 1 digit")])]):_vm._e(),(!_vm.$v.form.password.containsSpecial)?_c('div',{staticClass:"error-message"},[_c('small',[_vm._v("The password must have at least 1 special character")])]):_vm._e()]):_vm._e()]),_c('br'),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-danger",attrs:{"disabled":_vm.$v.form.$invalid}},[_vm._v("Sign In")]),(_vm.processing)?_c('app-spinner'):_vm._e()],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }